const DEFAULT_NUMBER_CONFIG = Object.freeze({
    locale: "en-us",
    digits: 2,
    empty: "",
});

export const numToString = (num, config = {}) => {
    /* Prints a number considering l10n. If no locale is passed, the locale of the browser will be used */
    const { locale, digits, empty } = { ...DEFAULT_NUMBER_CONFIG, ...config }; // !ORDER IS IMPORTANT HERE FOR CORRECT OVERWRITE
    return typeof num === "number"
        ? num.toLocaleString(locale, {
              minimumFractionDigits: 0,
              maximumFractionDigits: digits,
          })
        : empty;
};

export const numToPercentage = (num, config = {}) => {
    return `${numToString(num, config)}%`;
};

export const getPhoneMask = (code, phone) => {
    let mask;
    if (code == "+52") {
        if (phone && phone.length == 9) {
            mask = "(##) ###-####";
        } else {
            mask = "(##) ####-####";
        }
    } else {
        mask = "(###) ###-####";
    }
    return mask;
};
