<template>
    <div class="card mb-3">
        <div class="card-body">
            <section class="pb-4">
                <div class="d-flex">
                    <span class="user-profile__avatar user-profile__avatar--lg">
                        <img
                            class="user-profile__avatar user-profile__avatar--lg"
                            :src="profile.avatar"
                            :alt="profile.first_name + profile.last_name"
                        />
                        <a :href="planPage">
                            <plan-badge
                                :plan="profile.plan"
                                :class="billingBadgeClass"
                                size="lg"
                            >
                            </plan-badge>
                        </a>
                    </span>
                    <div class="flex-grow-1 ml-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <h6 class="mb-0">{{ profile.first_name }} {{ profile.last_name }}</h6>
                            <a
                                v-if="profile.profiles_count > 1"
                                data-toggle="modal"
                                data-target="#profile-full-list"
                                href="javascript:void(0);"
                            >
                                <i class="d-inline-block d-xl-none fa fa-exchange"></i>
                                <small class="d-none d-xl-inline-block text-right">{{
                                    $t("profile.switch")
                                }}</small>
                            </a>
                        </div>
                        <p class="mb-0">
                            <small>{{ $t("common.memberSince") }} {{ profile.created }}</small>
                        </p>
                        <p class="mb-0 mt-0 d-flex justify-content-between">
                            <upgrade-to-premium-link></upgrade-to-premium-link>
                            <plan-video></plan-video>
                        </p>
                    </div>
                </div>
            </section>
            <profile-all-steps></profile-all-steps>
        </div>
    </div>
</template>

<script>
    import { views } from "@/components/common/views-config.js";
    import PlanBadge from "@/components/common/PlanBadge.vue";
    import PlanVideo from "@/components/plans/PlanVideo.vue";
    import ProfileAllSteps from "@/components/profile/ProfileAllSteps.vue";
    import UpgradeToPremiumLink from "@/components/plans/UpgradeToPremiumLink.vue";

    const profile = window.profileData;

    export default {
        name: "ProfileWidget",
        data: function () {
            return {
                profile: profile,
                planPage: views.profile_plan(profile.slug),
            };
        },
        components: {
            PlanBadge,
            PlanVideo,
            ProfileAllSteps,
            UpgradeToPremiumLink,
        },
        computed: {
            billingBadgeClass() {
                return `user-profile__badge user-profile__badge--${this.profile.plan} user-profile__badge--lower`;
            },
        },
    };
</script>
