<template>
    <b-overlay
        :show="loading"
        rounded="sm"
    >
        <slot></slot>
    </b-overlay>
</template>

<script>
    import axios from "@/setup/axios-setup.js";
    import { errorToString } from "@/utils/errors.js";
    import Toast from "@/toast.js";
    const toast = new Toast();

    export default {
        name: "ConfirmAndWait",
        emits: ["cancel"],
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            params: {
                type: Object,
                default: () => ({}),
            },
            method: {
                type: String,
                default: "get",
                validator: (value) => ["get", "post", "put", "patch", "delete"].includes(value.toLowerCase()),
            },
            okCallback: {
                type: Function,
                default: null,
            },
            errorCallback: {
                type: Function,
                default: null,
            },
            successMessage: {
                type: String,
                default: "Action successfully completed",
            },
        },
        data() {
            return {
                loading: false,
            };
        },
        methods: {
            submit(event) {
                if (event) {
                    event.preventDefault();
                }
                this.loading = true;
                const config = {
                    method: this.method.toLowerCase(),
                    url: this.endpoint,
                    data: this.params,
                };
                axios(config)
                    .then((response) => {
                        toast.success(this.successMessage, "Success");
                        this.loading = false;

                        if (this.okCallback) {
                            this.okCallback(response);
                        }
                    })
                    .catch((error) => {
                        toast.error(errorToString(error), "Error");
                        this.loading = false;

                        if (this.errorCallback) {
                            this.errorCallback(error);
                        }
                    });
            },
        },
    };
</script>
