<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        okTitle="Edit"
        title="Edit posting"
        cancel-variant="light"
        method="patch"
        :params="form"
    >
        <label
            for="destination"
            class="form-label mt-3"
            >Destinations</label
        >
        <v-select
            id="destination"
            :options="destinations"
            :reduce="(d) => d.code"
            v-model="form.destination"
            label="title"
            :multiple="true"
            class="multiple"
        >
        </v-select>
        <label
            for="travel_type"
            class="form-label"
            >Travel Type</label
        >
        <v-select
            id="travel_type"
            :options="travelTypes"
            :reduce="(tt) => tt.code"
            v-model="form.travel_type"
            label="title"
            :multiple="true"
            class="multiple"
        >
        </v-select>
    </confirm-and-wait-modal>
</template>
<script>
    import { useMainStore } from "@/main-store.js";
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";
    import { endpoints } from "@/components/common/api-config.js";
    import vSelect from "vue-select";

    export default {
        name: "PostingCategorizeModal",
        components: {
            ConfirmAndWaitModal,
            vSelect,
        },
        props: {
            posting: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                mainStore: useMainStore(),
                form: {
                    travel_type: this.posting.travel_type,
                    destination: this.posting.destination,
                },
            };
        },
        computed: {
            endpoint() {
                return endpoints["postings-edit"](this.posting.id);
            },
            travelTypes() {
                return this.mainStore.travelTypes.map((tt) => {
                    return {
                        code: tt.slug,
                        title: tt.name,
                    };
                });
            },
            destinations() {
                return this.mainStore.destinations.map((d) => {
                    return {
                        code: d.slug,
                        title: d.name,
                    };
                });
            },
        },
    };
</script>
