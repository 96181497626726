// TODO: deprecate in favor of views
export const routes = {
    address: (profile) => `/${profile}/address/`,
    connect: (profile, app) => `/${profile}/${app}/settings`,
    details: (profile) => `/${profile}/details/`,
    emarketingDetail: (profile, id) => `/${profile}/emarketing/post/${id}`,
    emarketingReport: (profile, id) => `/${profile}/reports/emarketing/${id}`,
    personal_details: (profile) => `/${profile}/personal_details/`,
    removeApp: (profile, app) => `/${profile}/${app}/delete`,
    upgrade: (profile, perm, next) => `/${profile}/plan?perm=${perm}&redirect=${next}`,
};

const getProfileCalendarView = (profileSlug, dateTime = null, ids = null) => {
    const dateTimeParam = dateTime ? `?date=${dateTime}` : "?date=";
    const idsParam = ids ? `&ids=${ids}` : "";
    const allParams = [dateTimeParam, idsParam].filter((p) => p).join("");
    return `/${profileSlug}/calendar/${allParams}`;
};

export const views = {
    // django view name => url
    app_settings: (profileSlug, app) => `/${profileSlug}/${app}/settings/`,
    app_delete: (profileSlug, app) => `/${profileSlug}/${app}/delete/`,

    emarketing_post: (profileSlug) => `/${profileSlug}/emarketing/post/`,
    emarketing_reports_detail: (scheduledEmailCampaignId, profileSlug) => {
        return `/${profileSlug}/reports/emarketing/${scheduledEmailCampaignId}/`;
    },
    emarketing_scheduled_email_campaign: (scheduledEmailCampaignId, profileSlug) => {
        return `/${profileSlug}/emarketing/post/${scheduledEmailCampaignId}/`;
    },
    profile_plan: (profileSlug) => `/${profileSlug}/plan/`,
    seller_details: (profile) => `/${profile}/seller/`,
    profile_calendar: getProfileCalendarView,

    emarketing_signup: (profileSlug) => `/${profileSlug}/emarketing/signup/`,
    emarketing_contact_list: (profileSlug) => `/${profileSlug}/emarketing/contact-list/primary`,

    ai_preferences: (profileSlug) => `/${profileSlug}/ai_preferences/`,
    profile_connections: (profileSlug) => `/${profileSlug}/connections/`,

    account_settings: `/accounts/account_settings/`,
};
