<template>
    <b-modal
        centered
        :id="id"
    >
        <template #modal-header>
            <h5
                class="modal-title"
                id="app-status-modal-label"
            >
                <i class="fa fa-info-circle mr-2"></i> Reconnect {{ connectedAppConfig.name }}
            </h5>
            <button
                @click="closeModal"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </template>
        <div>
            <p>
                <i
                    :class="`fa ${connectedAppConfig.icon} em-icon text-${connectedAppConfig.id} align-middle fa-2x`"
                ></i>
                Your connection to {{ connectedAppConfig.name }} need to be reestablished.
            </p>
        </div>
        <template #modal-footer>
            <b-button
                variant="primary"
                @click="reconnect"
                >Reconnect</b-button
            >
        </template>
    </b-modal>
</template>

<script>
    import { appStringProp } from "@/utils/props.js";
    import { connectionConfigValues } from "@/components/apps-connection-config.js";

    export default {
        name: "ReconnectMessageModal",
        props: {
            id: {
                type: String,
                required: true,
            },
            app: appStringProp,
            reconnectUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                connectedAppConfig: connectionConfigValues[this.$props.app],
            };
        },
        methods: {
            reconnect() {
                window.location = this.reconnectUrl;
            },
            checkOpenModal() {
                const urlParams = new URLSearchParams(window.location.search);
                const reconnectingApp = urlParams.get("reconnect");
                if (reconnectingApp === this.$props.app) {
                    this.$bvModal.show(this.$props.id);
                }
            },
        },
        mounted() {
            this.checkOpenModal();
        },
    };
</script>
