<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        :id="`editSupplier${supplier.id}`"
        :okTitle="$t('supplier.okTitle')"
        :params="{ ...form }"
        :successMessage="$t('supplier.success')"
        :title="$t('supplier.title')"
        method="patch"
    >
        <div>
            <b-form>
                <b-form-group>
                    <div class="card-title">
                        <h5>{{ params.name }}</h5>
                    </div>
                    <div
                        class="mt-3"
                        v-if="params.label1 !== ''"
                    >
                        {{ params.label1 }}
                        <b-form-input
                            id="supplier-input-value1"
                            v-model="form.cobranded_value1"
                            :readonly="readonlyInsider"
                        ></b-form-input>
                    </div>
                    <div
                        class="mt-3"
                        v-if="params.label2 !== ''"
                    >
                        {{ params.label2 }}
                        <b-form-input
                            id="supplier-input-value2"
                            v-model="form.cobranded_value2"
                            :readonly="readonlyNonInsider"
                        ></b-form-input>
                    </div>
                    <div
                        class="mt-3"
                        v-if="params.label3 !== ''"
                    >
                        {{ params.label3 }}
                        <b-form-input
                            id="supplier-input-value3"
                            v-model="form.cobranded_value3"
                            :readonly="readonlyNonInsider"
                        ></b-form-input>
                    </div>
                    <div
                        class="mt-3"
                        v-if="params.label4 !== ''"
                    >
                        {{ params.label4 }}
                        <b-form-input
                            id="supplier-input-value4"
                            v-model="form.cobranded_value4"
                            :readonly="readonlyNonInsider"
                        ></b-form-input>
                    </div>
                    <b-form-checkbox
                        id="supplier-input-status"
                        class="mt-3"
                        v-model="form.status"
                    >
                        {{ params.disclaimer }}
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="params.profileCount > 1"
                        id="supplier-input-apply-to-all"
                        class="mt-3"
                        v-model="form.apply_to_all"
                    >
                        {{ $t("supplier.applyAll") }}
                    </b-form-checkbox>
                </b-form-group>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import { endpoints } from "@/components/common/api-config.js";
    import { useMainStore } from "@/main-store";
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";

    export default {
        name: "CobrandedEdit",
        components: { ConfirmAndWaitModal },
        props: {
            id: {
                type: String,
                default: "cobranded-edit",
            },
            supplier: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    cobranded_value1: "",
                    cobranded_value2: "",
                    cobranded_value3: "",
                    cobranded_value4: "",
                    status: false,
                    apply_to_all: false,
                },
                mainStore: useMainStore(),
            };
        },
        methods: {
            init(supplier) {
                var {
                    cobranded_value1,
                    cobranded_value2,
                    cobranded_value3,
                    cobranded_value4,
                    cobranded_disclaimer_date,
                    uses_approach_guides_api,
                } = supplier || {};
                var status = cobranded_disclaimer_date !== "";

                if (
                    uses_approach_guides_api &&
                    cobranded_value1 === "" &&
                    cobranded_value2 === "" &&
                    cobranded_value3 === "" &&
                    cobranded_value4 === ""
                ) {
                    cobranded_value2 = this.mainStore.profile.email;
                    cobranded_value3 = this.mainStore.profile.first_name;
                    cobranded_value4 = this.mainStore.profile.last_name;
                    status = true;
                }

                this.form = {
                    cobranded_value1: cobranded_value1,
                    cobranded_value2: cobranded_value2,
                    cobranded_value3: cobranded_value3,
                    cobranded_value4: cobranded_value4,
                    status: status,
                    apply_to_all: false,
                };
            },
        },
        watch: {
            supplier: {
                handler(newSupplier) {
                    this.init(newSupplier);
                },
                immediate: true,
            },
        },
        computed: {
            params() {
                if (!this.supplier) {
                    return {};
                }
                return {
                    name: this.supplier.supplier_label,
                    label1: this.supplier.cobranded_label1,
                    label2: this.supplier.cobranded_label2,
                    label3: this.supplier.cobranded_label3,
                    label4: this.supplier.cobranded_label4,
                    uses_approach_guides_api: this.supplier.uses_approach_guides_api,
                    disclaimer: this.supplier.cobranded_disclaimer,
                    profileSupplierPk: this.supplier.profilesupplier_id,
                    supplierPk: this.supplier.id,
                    profilePk: this.mainStore.profile.pk,
                    profileCount: this.mainStore.profile.profiles_count,
                };
            },
            endpoint() {
                return endpoints["cobrandedprofilesupplier-update"](
                    this.params.profileSupplierPk,
                    this.params.profilePk,
                    this.params.supplierPk
                );
            },
            readonlyInsider() {
                if (!this.supplier.uses_approach_guides_api) {
                    return false;
                }
                return this.supplier.cobranded_value1 === "";
            },
            readonlyNonInsider() {
                if (!this.supplier.uses_approach_guides_api) {
                    return false;
                }
                return this.supplier.cobranded_value1 !== "";
            },
        },
    };
</script>
