<template>
    <b-form-group
        :label="labelText"
        v-slot="{ ariaDescribedby }"
    >
        <b-form-checkbox-group
            id="socialnetwork"
            :aria-describedby="ariaDescribedby"
            name="socialnetwork"
        >
            <b-form-checkbox
                v-for="network in enabledNetworks"
                :key="network"
                :value="network"
                v-model="networks"
            >
                <i :class="['fa', connectionConfigValues[network].icon]"></i>
                {{ connectionConfigValues[network].name }}
            </b-form-checkbox>
        </b-form-checkbox-group>
    </b-form-group>
</template>

<script>
    import { connectionConfigValues } from "@/components/apps-connection-config";

    export default {
        name: "SocialNetworkPicker",
        emits: ["update:value"],
        props: {
            value: {
                type: Array,
                required: false,
            },
            label: {
                type: String,
                required: false,
            },
            selectAll: {
                type: Boolean,
                default: false,
            },
            availableNetworks: {
                type: Array,
                required: false,
                default: () => ["facebook", "instagramfb", "linkedin"],
            },
        },
        data() {
            return {
                profile: window.profileData,
                networks: this.$props.value,
                connectionConfigValues,
            };
        },
        computed: {
            labelText() {
                return this.$props.label || this.$t("common.socialNetworks");
            },
            enabledNetworks() {
                return this.availableNetworks.filter(
                    (network) => this.profile.applications[network].is_enabled
                );
            },
        },
        watch: {
            networks(newValue) {
                this.$emit("update:value", newValue);
            },
        },
        mounted() {
            if (this.selectAll) {
                this.selectAllNetworks();
            }
        },
        methods: {
            selectAllNetworks() {
                this.networks = this.availableNetworks.filter(
                    (network) => this.profile.applications[network].is_enabled
                );
            },
        },
    };
</script>
