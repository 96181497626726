<template>
    <div>
        <ul class="list-unstyled mb-0">
            <profile-one-step
                :link="detailsPage"
                :label="$t('common.profileComplete')"
                :isEnabled="isComplete"
                :showPlan="false"
            ></profile-one-step>
            <profile-one-step
                :link="accountSettingsPage"
                :label="$t('common.emailVerified')"
                :isEnabled="isVerified"
                :showPlan="false"
            ></profile-one-step>
            <profile-one-step
                :link="connectionPage"
                :label="$t('profile.platformConnected')"
                :isEnabled="isConnected"
                :showPlan="false"
            ></profile-one-step>
            <profile-one-step
                v-if="isProPlan"
                :link="aiPreferencesPage"
                :label="$t('profile.generatorConnected')"
                :isEnabled="isProSetup"
                :showPlan="true"
            ></profile-one-step>
        </ul>
    </div>
    <div>
        <b-progress
            :max="allSteps"
            variant="info"
        >
            <b-progress-bar
                :value="completedSteps"
                :label="`${((completedSteps / allSteps) * 100).toFixed(0)}%`"
            ></b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
    import { routes, views } from "@/components/common/views-config.js";
    import ProfileOneStep from "@/components/profile/ProfileOneStep.vue";

    const profile = window.profileData;

    export default {
        name: "ProfileAllSteps",
        data: function () {
            return {
                profile: profile,
                aiPreferencesPage: views.ai_preferences(profile.slug),
                connectionPage: views.profile_connections(profile.slug),
                accountSettingsPage: views.account_settings,
                detailsPage: routes.details(profile.slug),
            };
        },
        components: {
            ProfileOneStep,
        },
        computed: {
            isComplete() {
                return profile.completed;
            },
            isVerified() {
                return profile.email_verification;
            },
            isConnected() {
                return profile.applications && Object.values(profile.applications).some((app) => app.active);
            },
            isProSetup() {
                return profile.applications.generator.is_enabled;
            },
            isProPlan() {
                return profile.plan == "pro";
            },
            completedSteps() {
                var intComplete = this.isComplete ? 1 : 0;
                var intVerified = this.isVerified ? 1 : 0;
                var intConnected = this.isConnected ? 1 : 0;
                var intProSetup = this.isProPlan && this.isProSetup ? 1 : 0;
                return intComplete + intVerified + intConnected + intProSetup;
            },
            allSteps() {
                return this.isProPlan ? 4 : 3;
            },
        },
    };
</script>
