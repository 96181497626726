<template>
    <b-overlay
        :show="loading"
        rounded="sm"
    >
        <div class="d-flex align-items-start mb-3">
            <div
                v-if="previewUrl"
                class="mr-3 img-thumbnail img-container-fixed-size"
            >
                <b-img
                    :src="previewUrl"
                    fluid
                    alt="Image preview"
                ></b-img>
            </div>
            <div>
                <b-form-group :label="`Upload ${label} (Max: ${maxSizeMB}MB)`">
                    <b-form-file
                        v-model="image"
                        accept="image/*"
                        @change="validateFile"
                        :state="fileState"
                    >
                    </b-form-file>
                    {{ errorMessage }}
                    <b-form-invalid-feedback :state="fileState">
                        {{ errorMessage }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </div>
    </b-overlay>
</template>
<script>
    import axios from "@/setup/axios-setup.js";
    import Toast from "@/toast.js";
    const toast = new Toast();

    export default {
        name: "ImageUpload",
        emits: ["update:uploading"],
        props: {
            field: {
                type: Object,
                required: true,
            },
            endpoint: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: false,
                default: "Upload image",
            },
            imageInitial: {
                type: String,
                required: false,
            },
            maxSizeMB: {
                type: Number,
                default: 6,
            },
            uploading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                image: null,
                fileState: null,
                errorMessage: "",
                previewUrl: this.imageInitial ? this.imageInitial : null,
                loading: false,
            };
        },
        methods: {
            uploadImage() {
                if (!this.image || !this.image.size) {
                    return;
                }
                this.loading = true;
                this.$emit("update:uploading", true);
                const form_data = new FormData();
                form_data.append(this.field, this.image);
                axios
                    .patch(this.endpoint, form_data)
                    .then((response) => {
                        const data = response.data;
                        this.$emit("uploaded", data[this.field]);
                        toast.success("Image uploaded!", "Success");
                        this.loading = false;
                        this.$emit("update:uploading", false);
                    })
                    .catch(() => {
                        toast.error("Unable to upload image", "Error");
                        this.loading = false;
                        this.$emit("update:uploading", false);
                    });
            },
            validateFile(event) {
                const file = event.target.files[0];

                if (file) {
                    // Validate file size (6MB max)
                    if (file.size > this.maxSizeMB * 1024 * 1024) {
                        this.errorMessage = `File size must be less than ${this.maxSizeMB}MB.`;
                        this.fileState = false;
                        this.image = null;
                    } else {
                        this.errorMessage = "OK";
                        this.fileState = true;
                        this.image = file;
                        const urlCreator = window.URL || window.webkitURL;
                        this.previewUrl = urlCreator.createObjectURL(file);
                        this.uploadImage();
                    }
                }
            },
        },
    };
</script>
<style scoped>
    .img-container-fixed-size {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden; /* Hide overflow if needed */
    }

    .img-container-fixed-size img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover; /* or 'contain' if you want to fit without cropping */
    }
</style>
