<template>
    <div class="custom-filters">
        <b-form-group label="Destinations:">
            <b-form-checkbox
                v-for="d in destionationOptions"
                :key="d.slug"
                :value="d.slug"
                v-model="destionations"
                class="mt-2"
            >
                {{ d.name }}
            </b-form-checkbox>
        </b-form-group>
    </div>
</template>
<script>
    import { useMainStore } from "@/main-store";

    export default {
        name: "DestinationFilter",
        emits: ["update:value"],
        props: {
            props: {
                value: {
                    // A list of destionations
                    type: Array,
                    required: true,
                },
            },
        },
        data() {
            return {
                mainStore: useMainStore(),
                destionations: [],
            };
        },
        computed: {
            destionationOptions() {
                return this.mainStore.destinations;
            },
        },
        watch: {
            destionations(newValue) {
                this.$emit("update:value", newValue);
            },
        },
    };
</script>
