<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        okTitle="Reschedule"
        title="Reschedule posting"
        cancel-variant="light"
        method="patch"
        :params="form"
    >
        <b-alert
            v-if="posting.campaign.published"
            show
            variant="danger"
        >
            Be cautious: this will reschedule all scheduled posts which are NOT edited by the agent.
        </b-alert>
        <small class="text-muted">
            Reschedule posts by applying an offset. For instance: two days offset to a post scheduled on May
            6th will become May 8th. Numbers can be negative too
        </small>
        <b-form-group
            label="Hours"
            label-for="id_hours"
        >
            <b-form-input
                id="id_hours"
                type="number"
                name="hours"
                v-model="form.hours"
                placeholder="Hours"
                required
            ></b-form-input>
        </b-form-group>
        <b-form-group
            label="Days"
            label-for="id_days"
        >
            <b-form-input
                id="id_days"
                type="number"
                name="days"
                v-model="form.days"
                placeholder="Days"
                required
            ></b-form-input>
        </b-form-group>
    </confirm-and-wait-modal>
</template>
<script>
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";
    import { endpoints } from "@/components/common/api-config.js";

    export default {
        name: "PostingReschedule",
        components: {
            ConfirmAndWaitModal,
        },
        props: {
            posting: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    hours: null,
                    days: null,
                },
            };
        },
        computed: {
            endpoint() {
                return endpoints["postings-reschedule"](this.posting.id);
            },
        },
    };
</script>
