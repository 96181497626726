export const errorToString = (error) => {
    if (error.response.status === 400 && error.response.data) {
        const errorMessages = Object.entries(error.response.data)
            .map(([key, value]) => {
                return `<b>${key}:</b> ${Array.isArray(value) ? value.join(", ") : value} <br>`;
            })
            .join("\n ");
        return errorMessages;
    } else {
        return error.response.statusText;
    }
};

export const errorToDict = (error) => {
    if (error?.response?.status === 400 && error?.response?.data) {
        return Object.fromEntries(
            Object.entries(error.response.data).map(([key, value]) => [
                key,
                Array.isArray(value) ? value.join(", ") : value,
            ])
        );
    } else {
        return {};
    }
};
