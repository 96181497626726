<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        okTitle="Save"
        title="Edit posting"
        cancel-variant="light"
        method="patch"
        :params="form"
    >
        <b-alert
            v-if="posting.campaign.published"
            show
            variant="danger"
        >
            Be cautious: this will overwrite all images and links for scheduled posts. Messages are only
            updated when they are NOT changed by the agent.
        </b-alert>

        <b-form-group
            label="Message"
            description="Write your message here."
        >
            <b-form-textarea
                v-model="form.message"
                placeholder="Message"
                rows="10"
                required
            ></b-form-textarea>
        </b-form-group>
        <b-form-group
            v-if="ogs.length <= 0"
            label="Landing page"
            label-for="id_link"
            description="Enter the URL of the landing page."
        >
            <b-form-input
                id="id_link"
                type="url"
                name="link"
                v-model="form.link"
                placeholder="Landing page"
                required
            ></b-form-input>
        </b-form-group>
        <v-select
            v-else
            id="og_index"
            :options="ogs"
            :reduce="(og) => og.index"
            v-model="form.og_index"
            label="title"
            :clearable="false"
        >
        </v-select>
    </confirm-and-wait-modal>
</template>
<script>
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";
    import { endpoints } from "@/components/common/api-config.js";
    import vSelect from "vue-select";

    export default {
        name: "PostingEdit",
        components: {
            ConfirmAndWaitModal,
            vSelect,
        },
        props: {
            posting: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    message: this.posting.message,
                    link: this.posting.link,
                    og_index: this.posting.og_index,
                },
            };
        },
        computed: {
            endpoint() {
                return endpoints["postings-edit"](this.posting.id);
            },
            ogs() {
                // Get OGS from campaign and add index to display on select widget
                const ls = this.posting.campaign.ogs ? this.posting.campaign.ogs : [];
                return ls.map((item, index) => {
                    return { index, ...item };
                });
            },
        },
    };
</script>
