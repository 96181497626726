<template>
    <div class="d-flex align-items-center">
        <a
            v-if="additionalInfo"
            @click="showMessageModal"
            href="#"
            class="btn btn-sm btn-outline-info"
        >
            <i class="fa fa-info-circle"></i>
        </a>
        <connection-message-modal
            v-if="additionalInfo"
            :modalId="`${app_slug}-important-message-modal`"
            :appName="appStatus.name"
            :messageBody="additionalInfo"
        ></connection-message-modal>

        <a
            v-if="videoURL"
            @click="showVideoModal"
            href="#"
            class="btn btn-sm reset-link"
            >Watch a Quick Tutorial</a
        >
        <endpoint-fetcher
            :endpoint="endpoint"
            v-model:fetchedData="tutorialVideos"
            class="d-none"
        ></endpoint-fetcher>
        <modal-video
            v-if="videoURL"
            :modalId="'tutorial_' + app_slug"
            :videoSrc="videoURL"
        ></modal-video>
    </div>
</template>

<script>
    import ConnectionMessageModal from "@/components/connections/ConnectionMessageModal.vue";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import ModalVideo from "@/components/common/ModalVideo.vue";

    import { appStringProp } from "@/utils/props.js";
    import { endpoints } from "@/components/common/api-config.js";
    import { useMainStore } from "@/main-store";

    export default {
        name: "tutorial-video",
        props: {
            app_slug: appStringProp,
        },
        components: {
            ConnectionMessageModal,
            EndpointFetcher,
            ModalVideo,
        },
        data() {
            return {
                mainStore: useMainStore(),
                tutorialVideos: [],
                videoURL: "",
                additionalInfo: "",
            };
        },
        computed: {
            endpoint: function () {
                return endpoints.tutorial_videos(this.app_slug);
            },
            appStatus() {
                return this.mainStore[this.app_slug];
            },
        },
        watch: {
            tutorialVideos: {
                handler(tutorial_videos) {
                    const is_connected = this.mainStore[this.app_slug].active;
                    const tutorial_video = tutorial_videos.results.find(
                        (video) => video.show_when_connected === is_connected
                    );
                    this.videoURL = tutorial_video?.video;
                    this.additionalInfo = tutorial_video?.additional_info;
                },
            },
        },
        methods: {
            showMessageModal() {
                this.$bvModal.show(`${this.app_slug}-important-message-modal`);
            },
            showVideoModal() {
                this.$bvModal.show(`tutorial_${this.app_slug}`);
            },
        },
    };
</script>
