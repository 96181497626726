<template>
    <b-modal
        ref="placeholder-modal"
        cancel-variant="light"
        :title="appTitle"
        @ok="connect"
        ok-title="Connect"
        centered
    >
        <h1>{{ item.title }}</h1>
        <p>{{ item.message }}</p>
    </b-modal>

    <connect-link
        :app="app"
        v-if="app !== ''"
    ></connect-link>
    <upgrade-to-pro-modal
        :app="app"
        :id="`upgrade-${app}-modal`"
    ></upgrade-to-pro-modal>
</template>

<script>
    import { connectionConfigValues } from "@/components/apps-connection-config.js";
    import { useMainStore } from "@/main-store.js";
    import { views } from "@/components/common/views-config.js";

    import ConnectLink from "@/components/connections/ConnectLink.vue";
    import UpgradeToProModal from "@/components/plans/UpgradeToProModal.vue";

    export default {
        name: "PlaceholderModal",
        components: {
            ConnectLink,
            UpgradeToProModal,
        },
        data() {
            return {
                app: "",
                item: {},
                mainStore: useMainStore(),
            };
        },
        computed: {
            appTitle() {
                if (this.app) {
                    return this.mainStore[`${this.app}`].name;
                }
                return "";
            },
        },
        methods: {
            async show(app, item) {
                this.app = app;
                this.item = item;

                if (this.needsUpgrade()) {
                    await this.$nextTick();
                    this.$bvModal.show(`upgrade-${this.app}-modal`);
                } else {
                    this.$refs["placeholder-modal"].show();
                }
            },
            connect() {
                if (this.app === "emarketing") {
                    location.href = views.emarketing_signup(this.mainStore.profile.slug, this.app);
                    return;
                }

                const connectUrl = this.mainStore[`${this.app}`].connectURL;
                if (connectUrl) {
                    location.href = connectUrl;
                } else {
                    location.href = views.profile_connections(this.mainStore.profile.slug);
                }
            },
            needsUpgrade() {
                const config = connectionConfigValues[this.app];
                if (config && config.needsPro) {
                    if (this.mainStore.profile.plan !== "pro") {
                        return true;
                    }
                }
                return false;
            },
        },
    };
</script>
