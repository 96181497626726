<template>
    <div class="d-none">
        <endpoint-fetcher
            ref="facebookEndpoint"
            :endpoint="facebookEndpoint"
            :okCallback="loadPosts('facebook')"
            :showLoading="false"
            @update:loading="updateLoadingCalendar"
            v-if="selectedApps.includes('facebook') && connectedApps.includes('facebook')"
        >
        </endpoint-fetcher>
        <endpoint-fetcher
            ref="linkedinEndpoint"
            :endpoint="linkedinEndpoint"
            :okCallback="loadPosts('linkedin')"
            :showLoading="false"
            @update:loading="updateLoadingCalendar"
            v-if="selectedApps.includes('linkedin') && connectedApps.includes('linkedin')"
        >
        </endpoint-fetcher>
        <endpoint-fetcher
            ref="instagramEndpoint"
            :endpoint="instagramEndpoint"
            :okCallback="loadPosts('instagramfb')"
            :showLoading="false"
            @update:loading="updateLoadingCalendar"
            v-if="selectedApps.includes('instagramfb') && connectedApps.includes('instagramfb')"
        >
        </endpoint-fetcher>
        <endpoint-fetcher
            ref="emarketingEndpoint"
            :endpoint="emarketingEndpoint"
            :okCallback="loadEmarketingPosts()"
            :showLoading="false"
            @update:loading="updateLoadingCalendar"
            v-if="selectedApps.includes('emarketing') && connectedApps.includes('emarketing')"
        >
        </endpoint-fetcher>
        <placeholder-fetcher
            :selectedApps="selectedApps"
            :dateFrom="dateFrom"
            @add-calendar-event="addCalendarEvent"
            @remove-placeholders="removePlaceholders"
        >
        </placeholder-fetcher>
    </div>

    <b-overlay
        :show="loadingCalendar"
        spinner
        spinner-variant="primary"
    >
        <full-calendar
            class="bu-calendar"
            ref="calendar"
            :options="calendarOptions"
        >
        </full-calendar>
    </b-overlay>

    <calendar-modal
        ref="social-post-modal"
        @update:post="updatePost"
    ></calendar-modal>

    <placeholder-modal ref="placeholder-modal"></placeholder-modal>
</template>

<script>
    import bootstrapPlugin from "@fullcalendar/bootstrap";
    import CalendarModal from "@/components/calendar/CalendarModal.vue";
    import CalendarEvent from "@/components/calendar/CalendarEvent.vue";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import FullCalendar from "@fullcalendar/vue3";
    import moment from "moment-timezone";
    import PlaceholderFetcher from "@/components/calendar/PlaceholderFetcher.vue";
    import PlaceholderModal from "@/components/calendar/PlaceholderModal.vue";
    import timeGridPlugin from "@fullcalendar/timegrid";

    import { postingStatus } from "@/components/post/post-config.js";
    import { endpoints } from "@/components/common/api-config.js";
    import { defineComponent } from "vue";
    import { connectionConfigValues } from "@/components/apps-connection-config.js";
    import { setDate } from "@/utils/datetime.js";
    import { useMainStore } from "@/main-store.js";

    const mobileSize = 600;
    // Parse URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const parsedDate = setDate.parse(urlParams.get("date"));
    const initialDate = parsedDate ? parsedDate.toDate() : Date.now();
    const highlightedIds = urlParams.get("ids");
    const highlightedIdsArray = highlightedIds ? highlightedIds.split(",") : [];

    export default {
        name: "BUCalendar",
        components: {
            EndpointFetcher,
            FullCalendar,
            CalendarModal,
            PlaceholderFetcher,
            PlaceholderModal,
        },
        props: {
            selectedApps: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                dateFrom: null,
                dateUntil: null,
                loadingCalendar: false,
                // Calendar options
                calendarOptions: {
                    plugins: [bootstrapPlugin, dayGridPlugin, timeGridPlugin],
                    themeSystem: "bootstrap",
                    datesSet: this.setDates,
                    initialView: this.getInitialView(),
                    initialDate: initialDate,
                    eventContent: this.renderEventContent,
                    eventClick: this.handleEventClick,
                    defaultTimedEventDuration: "01:30",
                    allDaySlot: false,
                    titleFormat: { year: "numeric", month: "short", day: "numeric" },
                    headerToolbar: {
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridDay,dayGridWeek",
                    },
                    eventTimeFormat: {
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: "short",
                    },
                },
                mainStore: useMainStore(),
            };
        },
        computed: {
            calendar() {
                return this.$refs.calendar.getApi();
            },
            facebookEndpoint() {
                return this.getEndpoint("facebook");
            },
            linkedinEndpoint() {
                return this.getEndpoint("linkedin");
            },
            instagramEndpoint() {
                return this.getEndpoint("instagramfb");
            },
            emarketingEndpoint() {
                const profile = window.profileData;
                return endpoints["scheduledemailcampaign-list"](profile.pk, this.dateFrom, this.dateUntil);
            },
            connectedApps() {
                return Object.keys(connectionConfigValues).filter(
                    (app) => window.profileData.applications[app].is_enabled
                );
            },
        },
        methods: {
            getEndpoint(app) {
                const profile = window.profileData;
                const pageId = profile.applications[app].data.page_id;
                const provider = connectionConfigValues[app].provider;
                const status = null;
                const deleted = false;
                const ordering = "fire_at";
                const top = null;
                const createdFrom = null;
                const createdUntil = null;
                return endpoints["scheduledposting-list"](
                    pageId,
                    provider,
                    status,
                    deleted,
                    this.dateFrom,
                    this.dateUntil,
                    createdFrom,
                    createdUntil,
                    top,
                    ordering
                );
            },
            setDates(dateInfo) {
                this.dateFrom = moment(dateInfo.start);
                this.dateUntil = moment(dateInfo.end);
            },
            getPostId(app, post) {
                return `${app}-${post.id}`;
            },
            addPostToCalendar(app, post) {
                // Add helper properties to post
                post.fire_at_obj = moment(post.fire_at);
                post.provider = app;

                // ignore empty posts or deleted posts
                if (!post || post.deleted) {
                    return;
                }

                // Ignore PENDING posts on the past
                if (post.status === postingStatus.PENDING && post.fire_at_obj.isBefore(moment())) {
                    return;
                }

                // Ignore posts if it's not posted or pending
                if (![postingStatus.PENDING, postingStatus.POSTED].includes(post.status)) {
                    return;
                }
                // Add post to calendar
                const fire_at = typeof post.fire_at === "string" ? post.fire_at : post.fire_at.toISOString();
                // Only allow editing if the post is in the future and is pending
                const editable =
                    post.fire_at_obj.isAfter(moment()) && [postingStatus.PENDING].includes(post.status);
                this.calendar.addEvent({
                    id: this.getPostId(app, post),
                    title: post.message,
                    start: fire_at,
                    editable: editable,
                    extendedProps: {
                        app: app,
                        post: post,
                        enabled: editable,
                        imageUrl: post.image,
                        highligthed: highlightedIdsArray.includes(post.id.toString()),
                    },
                });
            },
            loadPosts(app) {
                return (response) => {
                    response.data.results.forEach((item) => {
                        // Generate the event ID
                        const eventId = this.getPostId(app, item);

                        // Check if an event with the same ID already exists
                        const existingEvent = this.calendar.getEventById(eventId);

                        // If no such event exists, add it to the calendar
                        if (!existingEvent) {
                            this.addPostToCalendar(app, item);
                        }
                    });
                };
            },
            addEmarketingToCalendar(app, emarketing) {
                // Add helper properties to post
                emarketing.fire_at_obj = moment(emarketing.fire_at);
                emarketing.provider = app;
                // Only add post if it's not deleted
                if (emarketing && !emarketing.deleted) {
                    const editable = emarketing.fire_at_obj.isAfter(moment());
                    this.calendar.addEvent({
                        id: this.getPostId(app, emarketing),
                        title: emarketing.name,
                        start: emarketing.fire_at,
                        editable: editable,
                        extendedProps: {
                            app: app,
                            post: emarketing,
                            enabled: editable,
                            imageUrl: emarketing.image_placeholder,
                        },
                    });
                }
            },
            loadEmarketingPosts() {
                return (response) => {
                    const app = "emarketing";
                    response.data.results.forEach((item) => {
                        item.fire_at_obj = moment(item.fire_at);
                        item.provider = app;

                        // Generate the event ID
                        const eventId = this.getPostId(app, item);

                        // Check if an event with the same ID already exists
                        const existingEvent = this.calendar.getEventById(eventId);

                        // If no such event exists, add it to the calendar
                        if (!existingEvent) {
                            this.addEmarketingToCalendar(app, item);
                        }
                    });
                };
            },
            renderEventContent(eventInfo) {
                // Create a Vue component instance
                const CalendarEventComponent = defineComponent(CalendarEvent);
                // Render the component using h() function
                return this.$createElement(CalendarEventComponent, {
                    props: {
                        event: eventInfo,
                    },
                });
            },
            getInitialView() {
                return window.innerWidth < mobileSize ? "dayGridDay" : "dayGridWeek";
            },
            handleEventClick(info) {
                const { event } = info;
                const post = event.extendedProps.post;
                const app = event.extendedProps.app;
                if (post.type === "placeholder") {
                    this.$refs["placeholder-modal"].show(app, post);
                } else {
                    this.$refs["social-post-modal"].show(app, post);
                }
            },
            updatePost(post) {
                const app = post.provider;
                const eventId = `${app}-${post.id}`;
                const existingEvent = this.calendar.getEventById(eventId);
                if (existingEvent) {
                    existingEvent.remove();
                }
                this.addPostToCalendar(app, post);
            },
            removeUnusedEvents() {
                if (this.calendar) {
                    const eventsToRemove = this.calendar
                        .getEvents()
                        .filter((event) => !this.selectedApps.includes(event.extendedProps.app));
                    eventsToRemove.forEach((event) => {
                        event.remove();
                    });
                }
            },
            removePlaceholders() {
                if (this.calendar) {
                    const eventsToRemove = this.calendar
                        .getEvents()
                        .filter((event) => event.extendedProps.post.type === "placeholder");
                    eventsToRemove.forEach((event) => {
                        event.remove();
                    });
                }
            },
            updateLoadingCalendar() {
                this.loadingCalendar = false;
                if ("facebookEndpoint" in this.$refs && this.$refs.facebookEndpoint) {
                    this.loadingCalendar = this.$refs.facebookEndpoint.loading || this.loadingCalendar;
                }
                if ("linkedinEndpoint" in this.$refs && this.$refs.linkedinEndpoint) {
                    this.loadingCalendar = this.$refs.linkedinEndpoint.loading || this.loadingCalendar;
                }
                if ("instagramEndpoint" in this.$refs && this.$refs.instagramEndpoint) {
                    this.loadingCalendar = this.$refs.instagramEndpoint.loading || this.loadingCalendar;
                }
                if ("emarketingEndpoint" in this.$refs && this.$refs.emarketingEndpoint) {
                    this.loadingCalendar = this.$refs.emarketingEndpoint.loading || this.loadingCalendar;
                }
            },
            addCalendarEvent(event) {
                this.calendar.addEvent(event);
            },
        },
        watch: {
            selectedApps() {
                this.removeUnusedEvents();
            },
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/_variables.scss";

    .calendar-container {
        position: relative;
    }

    .endpoint-fetcher-container {
        position: absolute;
        top: 40px;
        left: 50%;
    }

    .bu-calendar {
        height: calc(100vh - 100px);
        min-height: 500px;
        background-color: $header-bg-color;
        border-bottom-width: 2px;
        border: 1px solid #dee2e6;
    }

    .fc-header-toolbar {
        margin: 15px 15px !important;
    }

    .fc-event-main {
        overflow: hidden;
    }

    .event-card {
        overflow: hidden;
        color: black;
        border: 1px solid #ccc;
    }

    .event-card-disabled {
        background-color: #ccc !important;
    }

    @keyframes fadeOutBackground {
        0% {
            background-color: $primary;
        }

        100% {
            background-color: $primary-light;
        }
    }

    .event-card-highlighted {
        // background-color: $primary-light !important;
        animation: fadeOutBackground 3s ease forwards;
    }

    .event-card-time {
        text-transform: uppercase;
    }

    .event-card-image {
        width: 100%;
        height: auto;
    }

    .event-card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
