<script>
    import BuFormInput from "./BuFormInput.vue";

    export default {
        name: "BuFormWebsite",
        extends: BuFormInput,
        methods: {
            handleBlur() {
                if (
                    this.inputValue &&
                    !this.inputValue.startsWith("https://") &&
                    !this.inputValue.startsWith("http://")
                ) {
                    this.inputValue = `https://${this.inputValue}`;
                }
            },
        },
    };
</script>
