<template>
    <li class="d-flex justify-content-between mb-2">
        <span>
            <a
                class="reset-link"
                :href="link"
            >
                {{ label }}
            </a>
            <plan-badge
                v-if="showPlan"
                plan="pro"
                class="ml-1"
            ></plan-badge>
        </span>
        <i
            class="fa fa-lg"
            :class="isEnabled ? 'fa-check-circle-o text-info' : 'fa-circle-thin text-muted'"
        >
        </i>
    </li>
</template>

<script>
    import PlanBadge from "@/components/common/PlanBadge.vue";

    export default {
        name: "ProfileOneStep",
        props: {
            link: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            isEnabled: {
                type: Boolean,
                required: true,
            },
            showPlan: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            PlanBadge,
        },
    };
</script>
