<template>
    <div class="custom-filters">
        <b-form-group label="Travel Types:">
            <b-form-checkbox
                v-for="tt in travelTypeOptions"
                :key="tt.slug"
                :value="tt.slug"
                v-model="travelTypes"
                class="mt-2"
            >
                {{ tt.name }}
            </b-form-checkbox>
        </b-form-group>
    </div>
</template>
<script>
    import { useMainStore } from "@/main-store";

    export default {
        name: "TravelTypeFilter",
        emits: ["update:value"],
        props: {
            value: {
                // A list of travel types
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                mainStore: useMainStore(),
                travelTypes: [],
            };
        },
        computed: {
            travelTypeOptions() {
                return this.mainStore.travelTypes;
            },
        },
        watch: {
            travelTypes(newValue) {
                this.$emit("update:value", newValue);
            },
        },
    };
</script>
