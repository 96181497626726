<template>
    <div
        class="form-group"
        :class="codeClass"
    >
        <bu-form-select
            id="phone_country_code"
            v-model:value="codeValue"
            label="Code"
            :options="['+1', '+52']"
            :required="required"
            :errors="errorsCode"
        >
        </bu-form-select>
    </div>
    <div
        class="form-group"
        :class="phoneClass"
    >
        <bu-form-input
            id="phone"
            label="Phone"
            placeholder="Phone"
            description="We show your phone number to your potential customers on every landing page
            we create for you."
            v-model:value="phoneValue"
            :required="required"
            :errors="errorsPhone"
        >
        </bu-form-input>
    </div>
</template>
<script>
    import BuFormInput from "@/components/common/fields/BuFormInput.vue";
    import BuFormSelect from "@/components/common/fields/BuFormSelect.vue";
    import { getPhoneMask } from "@/utils/numbers.js";

    export default {
        name: "BuFormPhone",
        emits: ["update:code", "update:phone"],
        components: { BuFormInput, BuFormSelect },
        props: {
            code: {
                type: String,
                required: true,
            },
            phone: {
                type: String,
                required: true,
            },
            codeClass: {
                type: String,
                default: "",
            },
            phoneClass: {
                type: String,
                default: "",
            },
            errorsPhone: {
                type: Object,
                default: null,
            },
            errorsCode: {
                type: Object,
                default: null,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            phoneMask() {
                return getPhoneMask(this.codeValue, this.phoneValue);
            },
        },
        data() {
            return {
                codeValue: this.code,
                phoneValue: this.phone,
            };
        },
        watch: {
            codeValue(val) {
                this.$emit("update:code", val);
                this.handlePhoneInput();
            },
            phoneValue() {
                this.handlePhoneInput();
            },
            code: {
                handler() {
                    this.codeValue = this.code;
                    this.handlePhoneInput();
                },
                immediate: true,
            },
            phone: {
                handler() {
                    this.phoneValue = this.phone;
                    this.handlePhoneInput();
                },
                immediate: true,
            },
        },
        methods: {
            getPhoneEl() {
                return window.$(`#phone`);
            },
            handlePhoneInput(mask = null) {
                const el = this.getPhoneEl();
                const applyMask = mask ? mask : this.phoneMask;
                el.inputmask(applyMask);
                if (el.val()) {
                    this.$emit("update:phone", el.inputmask("unmaskedvalue"));
                }
            },
        },
        mounted() {
            this.handlePhoneInput();
        },
    };
</script>
