<template>
    <b-form-group
        :description="description"
        :label="label"
        :label-for="id"
        :class="{ 'bu-required-field': required }"
        :label-class="{ 'text-truncate d-block mr-1': truncateLabel }"
    >
        <b-form-input
            :id="id"
            :required="required"
            v-model="inputValue"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :minlength="minlength"
            :state="errors ? false : null"
            :type="type"
            @blur="handleBlur"
        ></b-form-input>
        <b-form-invalid-feedback :state="errors">
            {{ errors }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>
<script>
    export default {
        name: "BuFormInput",
        emits: ["update:value"],
        props: {
            id: {
                type: String,
                required: true,
            },
            value: {
                type: String,
                default: "",
            },
            type: {
                type: String,
                default: "text",
            },
            required: {
                type: Boolean,
                default: false,
            },
            maxlength: {
                type: Number,
                default: null,
            },
            minlength: {
                type: Number,
                default: null,
            },
            errors: {
                type: Object,
                default: null,
            },
            label: {
                type: String,
                default: "",
            },
            description: {
                type: String,
                default: "",
            },
            placeholder: {
                type: String,
                default: "",
            },
            truncateLabel: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                inputValue: this.value,
            };
        },
        watch: {
            // Original watchers
            value(val) {
                this.inputValue = val;
            },
            inputValue(val) {
                this.$emit("update:value", val);
            },
        },
        methods: {
            handleBlur() {},
        },
    };
</script>
<style lang="scss">
    .bu-required-field label::before {
        content: "* ";
        color: red;
    }
</style>
