<template>
    <b-alert
        show
        variant="info"
        v-if="!profile.completed"
        class="shake"
    >
        Connect one or more apps to get started.
    </b-alert>
    <div class="row">
        <apps-connection-card-item
            v-for="app in apps"
            :key="app"
            :app_slug="app"
        >
        </apps-connection-card-item>
    </div>
</template>

<script>
    import { connectionConfigValues } from "@/components/apps-connection-config.js";
    import AppsConnectionCardItem from "@/components/AppsConnectionCardItem.vue";

    export default {
        name: "ConnectionsDashboard",
        components: {
            AppsConnectionCardItem,
        },
        data() {
            return {
                apps: Object.values(connectionConfigValues).map((config) => config.id),
                profile: window.profileData,
            };
        },
    };
</script>
<style lang="css" scoped>
    .shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>
