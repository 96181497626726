<template>
    <b-overlay
        :show="loading"
        spinner
        spinner-variant="primary"
        style="min-height: 50px"
    >
        <b-form-group
            :description="description"
            :label="label"
            :label-for="id"
            :class="{ 'bu-required-field': required }"
            :label-class="{ 'text-truncate d-block': truncateLabel }"
        >
            <v-select
                :id="id"
                v-model="inputValue"
                :options="optionsValue"
                :clearable="false"
                :reduce="reduce"
                :label="labelOptionKey"
                :class="{ multiple: multiple }"
                :multiple="multiple"
                :state="errors ? false : null"
            >
                <template
                    #search="{ attributes, events }"
                    v-if="required"
                >
                    <input
                        v-bind="attributes"
                        class="vs__search"
                        :required="requiredAttr()"
                        v-on="events"
                    />
                </template>
            </v-select>
            <b-form-invalid-feedback :state="errors">
                {{ errors }}
            </b-form-invalid-feedback>
        </b-form-group>
    </b-overlay>
    <endpoint-fetcher
        v-if="endpoint"
        :endpoint="endpoint"
        method="get"
        v-model:fetchedData="optionsValue"
        :processResponse="processEndpointResponse"
        :showLoading="false"
        class="d-none"
        @update:loading="loading = $event"
    >
    </endpoint-fetcher>
</template>
<script>
    import vSelect from "vue-select";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";

    export default {
        name: "BuSelectInput",
        emits: ["update:value"],
        components: { vSelect, EndpointFetcher },
        props: {
            id: {
                type: String,
                required: true,
            },
            value: {
                type: String,
                default: "",
            },
            labelOptionKey: {
                type: String,
                default: null,
            },
            reduce: {
                type: Function,
                default: (v) => v,
            },
            options: {
                // Options used on the vue-select see: https://vue-select.org/guide/options.html#options-prop
                // allowed options: ['Canada', 'United States'] or [{label: 'Canada', code: 'CA'}]
                type: [Array, Object],
                default: () => [],
            },
            endpoint: {
                type: String,
                default: null,
            },
            processEndpointResponse: {
                type: Function,
                default: null,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            maxlength: {
                type: Number,
                default: null,
            },
            errors: {
                type: Object,
                default: null,
            },
            label: {
                type: String,
                default: "",
            },
            description: {
                type: String,
                default: "",
            },
            placeholder: {
                type: String,
                default: "",
            },
            truncateLabel: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                inputValue: this.value,
                optionsValue: this.options,
                loading: false,
            };
        },
        watch: {
            value(val) {
                this.inputValue = val;
            },
            inputValue(val) {
                this.$emit("update:value", val);
            },
            options(val) {
                this.optionsValue = val;
            },
        },
        methods: {
            requiredAttr() {
                if (!this.required) {
                    return false;
                }
                if (this.multiple && this.inputValue) {
                    if (this.inputValue instanceof Array) {
                        return this.inputValue.length == 0;
                    }
                }
                return !this.inputValue;
            },
        },
    };
</script>
<style lang="scss">
    .bu-required-field label::before {
        content: "* ";
        color: red;
    }
    .v-select {
        border-radius: 8px;
        border: 1px solid #ced4da;
    }
</style>
