<template>
    <endpoint-fetcher
        :endpoint="endpoint"
        :ok-callback="okCallback"
    >
    </endpoint-fetcher>
</template>
<script>
    import { endpoints } from "@/components/common/api-config.js";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import { useMainStore } from "@/main-store";

    export default {
        name: "DestinationsFetcher",
        components: { EndpointFetcher },
        data: function () {
            return {
                mainStore: useMainStore(),
                endpoint: endpoints["destinations"],
            };
        },
        methods: {
            okCallback(response) {
                this.mainStore["destinations"] = response.data.results;
            },
        },
    };
</script>
