<template>
    <confirm-and-wait-modal
        :id="id"
        :ok-disabled="!isFormOk"
        ok-title="Save"
        :params="{ ...params, ...form }"
        :form="$refs.form"
        successMessage="Saved!"
        title="Social Media Profile"
        :endpoint="profileDetailsEndpoint"
        method="patch"
        :okCallback="handleOkCallback"
        :errorCallback="handleErrorCallback"
        @shown="handleShownModal"
        @hidden="handleHiddenModal"
        centered
        size="xl"
    >
        <h4>Let's build your personal business card</h4>

        <b-form ref="form">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <bu-form-input
                        id="agency_name"
                        label="Business Name"
                        placeholder="Business Name"
                        description="Please provide your full legal business name which you own or operate under."
                        v-model:value="form.agency_name"
                        :required="true"
                        maxlength="128"
                        :errors="errors.agency_name"
                    >
                    </bu-form-input>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="form-group row">
                        <bu-form-phone
                            code-class="col-3 pr-1"
                            phone-class="col-6 px-1"
                            v-model:code="form.phone_country_code"
                            v-model:phone="form.phone"
                            :errors-code="errors.phone_country_code"
                            :errors-phone="errors.phone"
                            :required="true"
                        ></bu-form-phone>

                        <div class="form-group col-3 pl-1">
                            <bu-form-input
                                id="phone_extension"
                                label="Extension"
                                v-model:value="form.phone_extension"
                                maxlength="50"
                                :errors="errors.phone_extension"
                            >
                            </bu-form-input>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <bu-form-select
                        id="host_agency"
                        v-model:value="form.agency_host_name"
                        description="This helps customers recognize your brand and contact you directly. Note that you can select multiple values."
                        label="Do you make bookings through a host agency or franchise?"
                        :required="true"
                        :endpoint="agencyHostNameEndpoint"
                        :process-endpoint-response="(response) => response.data.results"
                        :multiple="true"
                        label-option-key="name"
                        :reduce="(v) => v.id"
                    >
                    </bu-form-select>
                </div>

                <div class="col-12 col-lg-6">
                    <bu-form-website
                        id="website"
                        label="Website Link"
                        v-model:value="form.website"
                        type="url"
                        placeholder="https://mywebsite.com"
                        :errors="errors.website"
                    >
                    </bu-form-website>
                </div>

                <div class="col-12 col-lg-6">
                    <image-upload
                        label="Profile Photo"
                        :image-initial="profile.avatar"
                        :endpoint="profileDetailsEndpoint"
                        field="profile_image"
                        :max-size-mb="6"
                        v-model:uploading="uploadingProfileImage"
                    ></image-upload>
                </div>

                <div class="col-12 col-lg-6">
                    <image-upload
                        label="Business Logo"
                        :image-initial="profile.logo"
                        :endpoint="profileDetailsEndpoint"
                        field="logo_image"
                        :max-size-mb="6"
                        v-model:uploading="uploadingLogoImage"
                    ></image-upload>
                </div>

                <div class="col-12 col-lg-4 pr-lg-1">
                    <bu-form-input
                        id="address"
                        label="Street"
                        v-model:value="form.address"
                        :errors="errors.address"
                        :required="true"
                        maxlength="128"
                    >
                    </bu-form-input>
                </div>
                <div class="col-6 col-lg-2 pr-1 px-lg-1">
                    <bu-form-input
                        id="city"
                        label="City"
                        v-model:value="form.city"
                        :errors="errors.city"
                        :required="true"
                        maxlength="100"
                    >
                    </bu-form-input>
                </div>

                <div class="col-3 col-lg-3 px-1">
                    <bu-form-select
                        id="state"
                        label="State / Province"
                        v-model:value="form.state"
                        :endpoint="statesEndpoint"
                        label-option-key="label"
                        :reduce="(v) => v.code"
                        :process-endpoint-response="(response) => response.data"
                        :required="true"
                        :truncate-label="true"
                    >
                    </bu-form-select>
                </div>
                <div class="col-3 col-lg-3 pl-1">
                    <bu-form-input
                        id="zip_code"
                        label="Zip / Postal Code"
                        v-model:value="form.zip_code"
                        :errors="errors.zip_code"
                        :required="true"
                        maxlength="20"
                        :truncate-label="true"
                    >
                    </bu-form-input>
                </div>
                <bu-form-license
                    v-model:value="form.agency_license"
                    :agency-license-description="agency_license_description"
                    :agency-license-number="agency_license_number"
                    wrapper-description-class="col-8 col-lg-8"
                    wrapper-number-class="col-4 col-lg-4"
                >
                </bu-form-license>
            </div>
        </b-form>

        <endpoint-fetcher
            :endpoint="profileDetailsEndpoint"
            method="get"
            v-model:fetchedData="profileDetails"
            :showLoading="false"
            class="d-none"
        ></endpoint-fetcher>
    </confirm-and-wait-modal>
</template>

<script>
    import { appStringProp } from "@/utils/props.js";
    import { useMainStore } from "@/main-store.js";
    import { endpoints } from "@/components/common/api-config.js";
    import ImageUpload from "@/components/common/ImageUpload.vue";
    import BuFormInput from "@/components/common/fields/BuFormInput.vue";
    import BuFormSelect from "@/components/common/fields/BuFormSelect.vue";
    import BuFormPhone from "@/components/common/fields/BuFormPhone.vue";
    import BuFormWebsite from "@/components/common/fields/BuFormWebsite.vue";
    import BuFormLicense from "@/components/common/fields/BuFormLicense.vue";
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import { adjustHelpLauncherPosition } from "@/utils/global.js";
    import { errorToDict } from "@/utils/errors.js";

    export default {
        name: "SocialMediaProfileModal",
        components: {
            ConfirmAndWaitModal,
            EndpointFetcher,
            ImageUpload,
            BuFormInput,
            BuFormSelect,
            BuFormPhone,
            BuFormLicense,
            BuFormWebsite,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
            app: appStringProp,
            redirectUrl: {
                type: String,
                required: false,
                default: "",
            },
        },
        data() {
            return {
                mainStore: useMainStore(),
                profileDetails: {},
                uploadingProfileImage: false,
                uploadingLogoImage: false,
                form: {
                    agency_name: "",
                    phone: "",
                    phone_country_code: "+1",
                    phone_extension: "",
                    website: "",
                    agency_host_name: [],
                    address: "",
                    city: "",
                    state: "",
                    zip_code: "",
                    agency_license: "",
                },
                errors: {},
                agency_license_number: "",
                agency_license_description: "",
            };
        },
        computed: {
            profile() {
                return this.mainStore["profile"];
            },
            isFormOk() {
                return !this.uploadingProfileImage && !this.uploadingLogoImage;
            },
            profileDetailsEndpoint() {
                return endpoints.profile_details(this.profile.pk);
            },
            agencyHostNameEndpoint() {
                return endpoints.agency_host_name(this.profile.country_pk);
            },
            statesEndpoint() {
                return endpoints.states(this.profile.country_pk);
            },
        },
        watch: {
            profileDetails: {
                immediate: true,
                handler(profileDetails) {
                    this.form.agency_name = profileDetails.agency_name;
                    this.form.phone = profileDetails.phone;
                    this.form.phone_country_code = profileDetails.phone_country_code;
                    this.form.phone_extension = profileDetails.phone_extension;
                    this.form.website = profileDetails.website;
                    this.form.agency_host_name = profileDetails.agency_host_name;
                    this.form.address = profileDetails.address;
                    this.form.city = profileDetails.city;
                    this.form.state = profileDetails.state;
                    this.form.zip_code = profileDetails.zip_code;
                    this.form.agency_license = profileDetails.agency_license;
                    this.agency_license_number = profileDetails.agency_license_number;
                    this.agency_license_description = profileDetails.agency_license_description;
                },
            },
        },
        methods: {
            handleShownModal() {
                adjustHelpLauncherPosition(true);
            },
            handleHiddenModal() {
                adjustHelpLauncherPosition(false);
            },
            handleOkCallback() {
                if (this.redirectUrl) {
                    window.location.href = this.redirectUrl;
                }
            },
            handleErrorCallback(error) {
                this.errors = errorToDict(error);
            },
        },
    };
</script>
