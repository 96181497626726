<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        okTitle="Delete"
        title="Delete posting"
        cancel-variant="light"
        method="delete"
        :params="form"
    >
        <b-alert
            show
            variant="danger"
            v-if="posting.campaign.published"
        >
            Be cautious: this will delete all scheduled posts for every agent.
        </b-alert>
        <p>Do you want to delete this post?</p>
    </confirm-and-wait-modal>
</template>
<script>
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";
    import { endpoints } from "@/components/common/api-config.js";

    export default {
        name: "PostingDelete",
        components: {
            ConfirmAndWaitModal,
        },
        props: {
            posting: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {};
        },
        computed: {
            endpoint() {
                return endpoints["postings-edit"](this.posting.id);
            },
        },
    };
</script>
