<template>
    <b-modal
        :busy="loading"
        :cancel-title="$t('cancelLabel')"
        :hide-header-close="loading"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        :title="title"
        @cancel="$emit('cancel')"
        @ok="confirmAction"
        @shown="handleShown"
        @hidden="$emit('hidden')"
        cancel-variant="light"
        ref="this-modal"
    >
        <b-overlay
            :show="loading"
            rounded="sm"
        >
            <div>
                <!-- content slot -->
                <slot></slot>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
    import axios from "@/setup/axios-setup.js";
    import Toast from "@/toast.js";
    import { errorToString } from "@/utils/errors.js";
    const toast = new Toast();

    export default {
        name: "confirm-and-wait-modal",
        emits: ["cancel", "shown", "hidden"],
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            params: {
                type: Object,
                default: () => ({}),
            },
            method: {
                type: String,
                default: "get",
                validator: (value) => ["get", "post", "put", "patch", "delete"].includes(value.toLowerCase()),
            },
            title: {
                type: String,
                default: "Confirm action",
            },
            okCallback: {
                type: Function,
                default: null,
            },
            errorCallback: {
                type: Function,
                default: null,
            },
            successMessage: {
                type: String,
                default: "Action successfully completed",
            },
            submitOnShow: {
                type: Boolean,
                default: false,
            },
            form: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                loading: false,
            };
        },
        methods: {
            confirmAction(event) {
                if (event) {
                    event.preventDefault();
                }
                if (this.form) {
                    if (!this.form.reportValidity()) {
                        // call form validation if it fails return without submitting
                        return;
                    }
                }
                this.loading = true;
                const config = {
                    method: this.method.toLowerCase(),
                    url: this.endpoint,
                    data: this.params,
                };
                axios(config)
                    .then((response) => {
                        this.$refs["this-modal"].hide();
                        toast.success(this.successMessage, "Success");
                        this.loading = false;

                        if (this.okCallback) {
                            this.okCallback(response);
                        }
                    })
                    .catch((error) => {
                        if (this.submitOnShow) {
                            this.$refs["this-modal"].hide();
                        }
                        toast.error(errorToString(error), "Error");
                        this.loading = false;

                        if (this.errorCallback) {
                            this.errorCallback(error);
                        }
                    });
            },
            handleShown() {
                this.$emit("shown");
                if (this.submitOnShow) {
                    this.confirmAction();
                }
            },
        },
        i18n: {
            messages: {
                en: {
                    cancelLabel: "Cancel",
                },
                es: {
                    cancelLabel: "Cancelar",
                },
                fr: {
                    cancelLabel: "Annuler",
                },
            },
        },
    };
</script>
