<template>
    <item-list
        ref="list"
        :endpoint="endpoint"
        :item-component="PostingCard"
        empty-text="No posts"
    ></item-list>
</template>

<script>
    import { shallowRef } from "vue";
    import PostingCard from "./PostingCard.vue";
    import ItemList from "@/components/common/ItemList.vue";

    export default {
        name: "PostingList",
        emits: ["update:value"],
        props: {
            value: {
                type: Object,
                required: false,
            },
        },
        components: {
            ItemList,
        },
        data() {
            return {
                PostingCard: shallowRef(PostingCard),
            };
        },
    };
</script>
