<template>
    <div :class="wrapperDescriptionClass">
        <bu-form-select
            id="agency_license_description"
            label="ID number you typically use?"
            v-model:value="agencyLicenseDescriptionValue"
            :endpoint="licenseOptionEndpoint"
            label-option-key="label"
            :reduce="(v) => v.code"
            :process-endpoint-response="(response) => response.data"
            :required="true"
            ref="agencyLicenseDescriptionSelect"
            :truncate-label="truncateLabel"
        >
        </bu-form-select>
    </div>
    <div :class="wrapperNumberClass">
        <bu-form-input
            id="agency_license_number"
            label="ID Number"
            v-model:value="agencyLicenseNumberValue"
            maxlength="50"
            :truncate-label="truncateLabel"
        >
        </bu-form-input>
    </div>
</template>
<script>
    import { endpoints } from "@/components/common/api-config.js";
    import BuFormInput from "@/components/common/fields/BuFormInput.vue";
    import BuFormSelect from "@/components/common/fields/BuFormSelect.vue";

    export default {
        name: "BuFormLicense",
        emits: ["update:value"],
        components: {
            BuFormSelect,
            BuFormInput,
        },
        props: {
            value: {
                type: String,
                required: true,
            },
            wrapperDescriptionClass: {
                type: String,
                default: "",
            },
            wrapperNumberClass: {
                type: String,
                default: "",
            },
            agencyLicenseNumber: {
                type: String,
                default: "",
            },
            agencyLicenseDescription: {
                type: String,
                default: "",
            },
            truncateLabel: {
                type: Boolean,
                default: true,
            },
        },
        data: function () {
            return {
                agencyLicenseNumberValue: this.agencyLicenseNumber,
                agencyLicenseDescriptionValue: this.agencyLicenseDescription,
            };
        },
        watch: {
            agencyLicenseNumber: {
                handler(value) {
                    this.agencyLicenseNumberValue = value;
                },
                immediate: true,
            },
            agencyLicenseDescription: {
                handler(value) {
                    this.agencyLicenseDescriptionValue = value;
                },
                immediate: true,
            },
            agencyLicenseNumberValue() {
                this.updateAgencyLicense();
            },
            agencyLicenseDescriptionValue() {
                this.updateAgencyLicense();
                this.handleLicenseInput();
            },
        },
        computed: {
            licenseOptionEndpoint() {
                return endpoints.license_option;
            },
            licenseMask() {
                return this.$refs.agencyLicenseDescriptionSelect.optionsValue.find(
                    (option) => option.code === this.agencyLicenseDescriptionValue
                )?.mask;
            },
        },
        methods: {
            handleLicenseInput() {
                const el = window.$("#agency_license_number");
                if (el.length > 0) {
                    el.inputmask(this.licenseMask);
                    // Add place holder for license number using mask
                    if (this.licenseMask) {
                        el.attr("placeholder", `For example: ${this.licenseMask}`);
                        el.prop("readonly", false);
                    } else {
                        el.attr("placeholder", "None");
                        el.val("");
                        el.prop("readonly", true);
                        this.agencyLicenseNumberValue = "";
                    }
                    this.agencyLicenseNumberValue = el.inputmask("unmaskedvalue");
                }
            },
            updateAgencyLicense() {
                let agencyLicense = this.agencyLicenseDescriptionValue;
                if (
                    this.agencyLicenseNumberValue &&
                    this.agencyLicenseNumberValue.length > 0 &&
                    this.licenseMask
                ) {
                    // If theres a number with mask concatenate and save it
                    agencyLicense = `${this.agencyLicenseDescriptionValue} ${this.agencyLicenseNumberValue}`;
                }
                this.$emit("update:value", agencyLicense);
            },
        },
    };
</script>
