<template>
    <slot name="content">
        <li class="d-flex justify-content-between align-items-center mb-3">
            <!--  left section -->
            <span class="d-flex align-items-center">
                <i
                    :class="`fa ${connectedAppConfig.icon} fa-2x text-${connectedAppConfig.id} align-middle`"
                ></i>
                <span
                    class="align-middle mx-2 text-capitalize"
                    v-text="isConnected ? appData.name : appName"
                ></span>
                <plan-badge
                    v-if="connectedAppConfig.plan === 'pro'"
                    plan="pro"
                ></plan-badge>
            </span>

            <!-- right section -->
            <div v-if="'plan' in connectedAppConfig && profile.plan != connectedAppConfig['plan']">
                <a
                    v-if="!isConnected"
                    @click="showUpgradeModal()"
                    href="javascript:void(0)"
                >
                    <span class="reset-link">
                        <small>{{ $t("connection.itemLabelClickToConnect") }}</small>
                    </span>
                </a>
                <small
                    v-else
                    class="text-green"
                    >{{ $t("connection.itemLabelConnected") }}</small
                >
            </div>
            <div v-else>
                <connect-link :app="connectedAppConfig.id"></connect-link>
            </div>
        </li>
    </slot>

    <!-- item's modals  -->
    <div
        :id="`${app}-item-modals`"
        class="d-none"
    >
        <app-disconnected-modal :app="app"></app-disconnected-modal>
        <page-selector-modal
            :id="`${app}-page-selector`"
            :app="app"
        ></page-selector-modal>
        <upgrade-to-pro-modal
            :app="app"
            :id="`upgrade-${app}-modal`"
        ></upgrade-to-pro-modal>
    </div>

    <!-- status sync -->
    <endpoint-fetcher
        class="d-none"
        :endpoint="statusEndpoint"
        :process-response="processResponse"
        :show-loading="false"
    ></endpoint-fetcher>

    <!-- Account data sync -->
    <endpoint-fetcher
        v-if="isConnected"
        class="d-none"
        :endpoint="accountDataEndpoint"
        :process-response="processAccountDataResponse"
        :show-loading="false"
    ></endpoint-fetcher>
</template>

<script>
    import AppDisconnectedModal from "@/components/AppDisconnectedModal.vue";
    import ConnectLink from "@/components/connections/ConnectLink.vue";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import PageSelectorModal from "@/components/connections/PageSelectorModal.vue";
    import UpgradeToProModal from "@/components/plans/UpgradeToProModal.vue";

    import { appStringProp } from "@/utils/props.js";
    import { connectionConfigValues } from "./apps-connection-config.js";
    import { endpoints } from "./common/api-config";
    import { useMainStore } from "@/main-store.js";

    export default {
        name: "AppsConnectionItem",
        props: {
            profile: {
                type: Object,
                required: true,
            },
            app: appStringProp,
        },
        components: {
            AppDisconnectedModal,
            ConnectLink,
            EndpointFetcher,
            PageSelectorModal,
            UpgradeToProModal,
        },
        data: function () {
            return {
                isConnected: false,
                connectedAppConfig: connectionConfigValues[this.$props.app],
                mainStore: useMainStore(),
            };
        },
        methods: {
            processResponse(response) {
                const { connected, installed } = response.data;
                this.updateConectionStatus(this.$props.app, installed, connected);
            },
            processAccountDataResponse(response) {
                // update profileData app with the new data
                if (response.data && Object.entries(response.data).length) {
                    // Update global profileData
                    window.profileData.applications[this.$props.app].data = response.data;
                    // Update profile store
                    this.mainStore.$patch({ profile: window.profileData });
                    // Update app data
                    this.mainStore[`${this.$props.app}_data`] = response.data;
                }
            },
            updateConectionStatus(app, installed, connected) {
                // update local:
                this.isConnected = connected;
                // update profileData object in window
                window.profileData.applications[app].installed = installed;
                window.profileData.applications[app].connected = connected;
                // Update main store
                this.mainStore[app] = window.profileData.applications[app];
            },
            showUpgradeModal() {
                this.$bvModal.show(`upgrade-${this.app}-modal`);
            },
            showDisconnectModal(event, app) {
                event.preventDefault();
                this.$bvModal.show(`${app}-disconnected-modal`);
            },
        },
        computed: {
            appData() {
                return this.mainStore[`${this.$props.app}_data`];
            },
            appName() {
                return this.mainStore[`${this.$props.app}`].name;
            },
            statusEndpoint() {
                return endpoints["profilesocial-status"](this.$props.profile.pk, this.$props.app);
            },
            accountDataEndpoint() {
                return endpoints["profilesocial-accountdata"](this.$props.profile.pk, this.$props.app);
            },
        },
    };
</script>
