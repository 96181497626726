<template>
    <confirm-and-wait
        ref="profileSetup"
        :params="form"
        successMessage="Saved!"
        :endpoint="profileDetailsEndpoint"
        method="patch"
        :okCallback="handleOkCallback"
        :errorCallback="handleErrorCallback"
    >
        <b-form
            ref="form"
            @submit.prevent="handleFormSubmit"
        >
            <div class="row">
                <div class="col-12">
                    <bu-form-input
                        id="first_name"
                        label="First name"
                        v-model:value="form.first_name"
                        :required="true"
                        maxlength="100"
                        :errors="errors.first_name"
                    >
                    </bu-form-input>
                    <bu-form-input
                        id="last_name"
                        label="Last name"
                        v-model:value="form.last_name"
                        :required="true"
                        maxlength="100"
                        :errors="errors.last_name"
                    >
                    </bu-form-input>
                    <bu-form-input
                        id="email"
                        label="Email"
                        v-model:value="form.email"
                        :required="true"
                        type="email"
                        :errors="errors.email"
                    >
                    </bu-form-input>
                    <bu-form-select
                        id="country"
                        label="Country"
                        v-model:value="form.country"
                        :endpoint="countryEndpoint"
                        label-option-key="name"
                        :reduce="(v) => v.id"
                        :process-endpoint-response="(response) => response.data.results"
                        :required="true"
                        :errors="errors.country"
                    >
                    </bu-form-select>
                </div>
                <div class="col-12 mt-3">
                    <b-button
                        type="submit"
                        variant="primary"
                    >
                        Save
                    </b-button>
                </div>
            </div>
        </b-form>
    </confirm-and-wait>
    <endpoint-fetcher
        :endpoint="profileDetailsEndpoint"
        method="get"
        v-model:fetchedData="profileDetails"
        :showLoading="false"
        class="d-none"
    ></endpoint-fetcher>
</template>
<script>
    import ConfirmAndWait from "@/components/common/ConfirmAndWait.vue";
    import BuFormInput from "@/components/common/fields/BuFormInput.vue";
    import BuFormSelect from "@/components/common/fields/BuFormSelect.vue";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import { endpoints } from "@/components/common/api-config.js";
    import { errorToDict } from "@/utils/errors.js";

    export default {
        name: "ProfileSetup",
        components: { ConfirmAndWait, BuFormInput, BuFormSelect, EndpointFetcher },
        props: {
            redirectUrl: {
                type: String,
                required: false,
                default: "",
            },
        },
        data() {
            return {
                profile: window.profileData,
                profileDetails: {},
                errors: [],
                form: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    country: null,
                },
            };
        },
        computed: {
            profileDetailsEndpoint() {
                return endpoints["profile_details"](this.profile.pk);
            },
            countryEndpoint() {
                return endpoints["countries"];
            },
        },
        watch: {
            profileDetails: {
                immediate: true,
                handler(profileDetails) {
                    this.form.first_name = profileDetails.first_name;
                    this.form.last_name = profileDetails.last_name;
                    this.form.email = profileDetails.email;
                    this.form.country = profileDetails.country;
                },
            },
        },
        methods: {
            handleOkCallback() {
                if (this.redirectUrl) {
                    window.location.href = this.redirectUrl;
                }
            },
            handleErrorCallback(error) {
                this.errors = errorToDict(error);
            },
            handleFormSubmit(event) {
                this.$refs.profileSetup.submit(event);
            },
        },
    };
</script>
