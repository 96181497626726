<template>
    <a
        href="javascript:void(0);"
        @click="showVideoModal('video-modal')"
        class="reset-link text-right"
    >
        <i
            class="fa fa-youtube-play text-dark"
            aria-hidden="true"
        ></i>
        <small class="ml-1">
            <span v-if="isProPlan"> How-to setup your PRO membership </span>
            <span v-if="!isProPlan"> Learn more </span>
        </small>
    </a>
    <b-modal
        body-class="no-padding"
        ref="video-modal"
        :title="getVideoTitle"
        size="xl"
        hide-footer
        hide-header
        centered
    >
        <b-embed
            type="iframe"
            aspect="16by9"
            :src="getVideoLink"
            allowfullscreen
        >
        </b-embed>
        <a
            @click="hideVideoModal('video-modal')"
            class="modal-floating-close"
            href="javascript:void(0);"
        >
            <i
                class="fa fa-times fa-lg align-middle"
                aria-hidden="true"
            ></i>
        </a>
    </b-modal>
</template>

<script>
    const profile = window.profileData;

    export default {
        name: "PlanVideo",
        data: function () {
            return {
                profile: profile,
            };
        },
        methods: {
            showVideoModal(target) {
                this.$refs[target].show();
            },
            hideVideoModal(target) {
                this.$refs[target].hide();
            },
        },
        computed: {
            isProPlan() {
                return profile.plan == "pro";
            },
            getVideoLink() {
                if (this.isProPlan) {
                    return window.billing_setup_video_url;
                } else {
                    return window.billing_mkt_video_url;
                }
            },
            getVideoTitle() {
                if (this.isProPlan) {
                    return "How to setup BranchUp PRO membership";
                } else {
                    return "BranchUp PRO Membership";
                }
            },
        },
    };
</script>
