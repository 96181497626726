<template>
    <article
        v-if="posting"
        class="card card--shadow-border mb-4"
    >
        <main class="card-footer card-footer--no-padding card-footer--no-link">
            <b-alert
                v-if="posting.type === 'reel' && posting.image.endsWith('api/postings/')"
                show
                variant="warning"
            >
                <i class="fa fa-refresh fa-spin"></i>
                Reel is processing. Image will be available soon. Please wait to publish the campaign for
                improved performance.
            </b-alert>
            <video
                v-if="posting.video"
                :src="posting.video"
                :poster="posting.image"
                controls
                controlsList="nodownload"
                class="embed-responsive embed-responsive-21by9 post-video"
            ></video>

            <div
                v-if="!posting.video && posting.image"
                class="overflow-hidden rounded-bottom"
            >
                <div class="image-container">
                    <img
                        v-lazy="{ src: posting.image }"
                        alt=""
                        class="img-fluid"
                    />
                </div>
            </div>
        </main>
        <section class="card-body d-flex flex-column">
            <h4 v-if="posting.campaign.type !== 'library'">
                <post-status-label :post="posting"></post-status-label>
            </h4>
            <p class="text-breakspaces mb-2 text-dark text-fixed-height">{{ posting.message }}</p>

            <div v-if="posting.landing_page_link">
                <small
                    v-if="posting.og_data"
                    class="text-muted"
                    >{{ posting.og_data.title }}</small
                ><br />
                <a
                    :href="posting.landing_page_link"
                    class="card-link"
                    target="_blank"
                    v-if="posting.landing_page_link"
                    >Landing page <i class="fa fa-external-link small text-muted"></i>
                </a>
                <a
                    :href="'https://developers.facebook.com/tools/debug/?q=' + posting.landing_page_link"
                    class="card-link"
                    target="_blank"
                    v-if="posting.landing_page_link"
                >
                    <i class="fa fa-bug small text-muted"></i>
                </a>
                <a
                    :href="'https://www.facebook.com/sharer/sharer.php?u=' + posting.landing_page_link"
                    class="card-link"
                    target="_blank"
                    v-if="posting.landing_page_link"
                >
                    <i class="fa fa-facebook small text-muted"></i>
                </a>
            </div>

            <hr v-if="posting.travel_type.length > 0 || posting.destination.length > 0" />

            <div class="text-center mt-auto no-print">
                <div>
                    <b-badge
                        v-for="tt in posting.travel_type"
                        :key="tt.slug"
                        variant="primary"
                        class="mr-1"
                    >
                        {{ tt }}
                    </b-badge>
                </div>
                <div>
                    <b-badge
                        v-for="d in posting.destination"
                        :key="d.slug"
                        variant="info"
                        class="mr-1"
                    >
                        {{ d }}
                    </b-badge>
                </div>
            </div>
        </section>
        <div class="card-footer text-muted no-print">
            <div v-if="published">
                <i
                    class="fa fa-checked text-success mr-1"
                    aria-hidden="true"
                ></i>
                Posted
            </div>
            <div
                v-if="!published"
                class="no-print mt-2 text-left"
            >
                <b-button
                    v-b-modal="deleteModalId"
                    variant="outline-dark"
                    class="mr-2"
                >
                    <i class="fa fa-trash-o"></i>
                </b-button>
                <b-button
                    v-b-modal="editModalId"
                    variant="outline-dark"
                    class="mr-2"
                >
                    <i class="fa fa-pencil"></i>
                </b-button>
                <b-button
                    v-b-modal="rescheduleModalId"
                    variant="outline-dark"
                    class="mr-2"
                >
                    <i class="fa fa-clock-o"></i>
                </b-button>
                <b-button
                    v-b-modal="categorizeModalId"
                    variant="outline-dark"
                    class="mr-2"
                >
                    <i class="fa fa-tags"></i>
                </b-button>
            </div>
            <div v-if="posting.related_data">
                <hr />
                <b-link
                    v-b-toggle="'collapse-' + posting.id"
                    class="p-2 d-flex justify-content-between align-items-center text-dark"
                >
                    <span>Scheduled {{ posting.related_data.autoschedule_percentage }}%</span>
                    <i class="fa fa-chevron-down"></i>
                </b-link>
                <b-collapse :id="'collapse-' + posting.id">
                    <b-table
                        :items="[posting.related_data.scheduled_posting]"
                        :fields="['posted', 'pending', 'other', 'total']"
                    ></b-table>
                </b-collapse>
            </div>
        </div>
        <posting-edit-modal
            :id="editModalId"
            :posting="posting"
            :okCallback="editPosting"
        ></posting-edit-modal>
        <posting-delete-modal
            :id="deleteModalId"
            :posting="posting"
            :okCallback="deletePosting"
        ></posting-delete-modal>
        <posting-reschedule-modal
            :id="rescheduleModalId"
            :posting="posting"
            :okCallback="reschedulePosting"
        ></posting-reschedule-modal>
        <posting-categorize-modal
            :id="categorizeModalId"
            :posting="posting"
            :okCallback="editPosting"
        >
        </posting-categorize-modal>
    </article>
</template>
<script>
    import PostStatusLabel from "@/components/post/PostStatusLabel.vue";
    import PostingEditModal from "./PostingEditModal.vue";
    import PostingDeleteModal from "./PostingDeleteModal.vue";
    import PostingRescheduleModal from "./PostingRescheduleModal.vue";
    import PostingCategorizeModal from "./PostingCategorizeModal.vue";
    import { formatDate, setDate } from "@/utils/datetime.js";

    export default {
        name: "PostingCard",
        emits: ["update-item"],
        components: {
            PostStatusLabel,
            PostingEditModal,
            PostingDeleteModal,
            PostingRescheduleModal,
            PostingCategorizeModal,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                posting: this.parseItem(this.$props.item),
            };
        },
        computed: {
            editModalId() {
                return `editPosting${this.posting.id}`;
            },
            deleteModalId() {
                return `deletePosting${this.posting.id}`;
            },
            rescheduleModalId() {
                return `reschedulePosting${this.posting.id}`;
            },
            categorizeModalId() {
                return `categorizePosting${this.posting.id}`;
            },
            published() {
                // Posting library are not considered published
                return this.posting.fire_at <= setDate.now() && this.posting.campaign.type !== "library";
            },
        },
        methods: {
            parseItem(item) {
                if (item && item.fire_at) {
                    item.fire_at = formatDate.parseWithTimeZone(item.fire_at);
                    item.fire_at_formatted = item.fire_at.format("LLLL");
                }
                return item;
            },
            editPosting(response) {
                this.posting = this.parseItem(response.data);
                this.$emit("update-item", this.posting);
            },
            deletePosting(response) {
                this.$emit("delete-item", response.data);
            },
            reschedulePosting(response) {
                // emit an add-item to refresh the list
                this.posting = this.parseItem(response.data);
                this.$emit("add-item", this.posting);
            },
        },
    };
</script>
<style scoped>
    .image-container {
        position: relative;
        width: 100%;
        max-width: 1200px; /* Optional */
        padding-top: 52.36%; /* Creates a 1.91:1 aspect ratio (100 / 1.91) */
        overflow: hidden;
    }

    .image-container img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
        width: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
</style>
