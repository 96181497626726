<template>
    <div class="row">
        <div
            v-if="campaignType === 'library'"
            class="col-md-3 no-print"
        >
            <travel-type-fetcher class="d-none"></travel-type-fetcher>
            <destinations-fetcher class="d-none"></destinations-fetcher>
            <destination-filter v-model:value="destinations"></destination-filter>
            <travel-type-filter v-model:value="travelTypes"></travel-type-filter>
        </div>
        <div :class="campaignType === 'library' ? 'col-md-9' : 'col-12'">
            <posting-list
                :endpoint="postingEndpoint"
                itemWrapperClass="row"
                itemClass="col-md-6"
                paginatorClass="col-12"
            >
            </posting-list>
        </div>
    </div>
</template>

<script>
    import PostingList from "@/components/admin/posting/PostingList.vue";
    import { endpoints } from "@/components/common/api-config.js";
    import TravelTypeFetcher from "@/components/common/TravelTypeFetcher.vue";
    import DestinationsFetcher from "@/components/common/DestinationsFetcher.vue";
    import TravelTypeFilter from "@/components/common/TravelTypeFilter.vue";
    import DestinationFilter from "@/components/common/DestinationFilter.vue";

    export default {
        name: "CampaignDetails",
        components: {
            PostingList,
            TravelTypeFilter,
            DestinationFilter,
            TravelTypeFetcher,
            DestinationsFetcher,
        },
        props: {
            campaignPk: {
                type: String,
                required: true,
            },
            campaignType: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                travelTypes: [],
                destinations: [],
            };
        },
        computed: {
            postingEndpoint() {
                return endpoints["postings"](this.campaignPk, this.travelTypes, this.destinations);
            },
        },
    };
</script>
