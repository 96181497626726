<template>
    <div>
        <div class="card mb-4">
            <div class="card-body text-center">
                <div class="card-title">
                    <h5>{{ supplier.supplier_label }}</h5>
                    <span class="font-italic">{{ supplier.cobranded_tagline }}&nbsp;</span>
                </div>
                <div class="mt-3">
                    <strong> {{ $t("supplier.CobrandedWebsite") }} :</strong>
                    &nbsp;<span :class="[displayClass]">{{ displayStatus }}</span>
                </div>
                <div class="mt-3">
                    <strong>
                        {{ supplier.cobranded_label1 }}
                        <span v-if="supplier.cobranded_label1 !== ''">:</span>
                    </strong>
                    &nbsp;{{ supplier.cobranded_value1 }}
                </div>
                <div class="mt-3">
                    <strong>
                        {{ supplier.cobranded_label2 }}
                        <span v-if="supplier.cobranded_label2 !== ''">:</span>
                    </strong>
                    &nbsp;{{ supplier.cobranded_value2 }}
                </div>
                <div class="mt-3">
                    <strong>
                        {{ supplier.cobranded_label3 }}
                        <span v-if="supplier.cobranded_label3 !== ''">:</span>
                    </strong>
                    &nbsp;{{ supplier.cobranded_value3 }}
                </div>
                <div class="mt-3">
                    <strong>
                        {{ supplier.cobranded_label4 }}
                        <span v-if="supplier.cobranded_label4 !== ''">:</span>
                    </strong>
                    &nbsp;{{ supplier.cobranded_value4 }}
                </div>
                <b-button
                    @click="showModal(`editSupplier${supplier.id}`)"
                    class="btn-bu btn-bu-warning btn-bu-no-icon mt-3"
                    variant="warning"
                >
                    {{ $t("supplier.button") }}
                </b-button>
                <div class="mt-3">
                    {{ $t("supplier.Click") }}
                    <a
                        :href="supplier.cobranded_video_link"
                        target="_blank"
                        >{{ $t("supplier.Here") }}</a
                    >
                    {{ $t("supplier.VideoText") }}
                </div>
                <div class="mt-3">
                    {{ $t("supplier.Click") }}
                    <a
                        :href="supplier.cobranded_help_link"
                        target="_blank"
                        >{{ $t("supplier.Here") }}</a
                    >
                    {{ supplier.cobranded_help_text }}
                </div>
                <div class="mt-3">
                    {{ $t("supplier.Click") }}
                    <a
                        :href="supplier.cobranded_faq_link"
                        target="_blank"
                        >{{ $t("supplier.Here") }}</a
                    >
                    {{ $t("supplier.FAQsText") }}
                </div>
                <cobranded-edit
                    :okCallback="updateParent"
                    :supplier="supplier"
                ></cobranded-edit>
            </div>
        </div>
    </div>
</template>

<script>
    import CobrandedEdit from "./CobrandedEdit.vue";

    export default {
        name: "CobrandedCard",
        emits: ["add-item"],
        components: { CobrandedEdit },
        props: {
            id: {
                type: String,
                default: "cobranded-card",
            },
            item: {
                type: Object,
                required: true,
            },
        },
        data: function () {
            return {
                supplier: this.$props.item,
            };
        },
        computed: {
            displayClass() {
                return this.supplier.cobranded_disclaimer_date === "" ? "text-success" : "text-info";
            },
            displayStatus() {
                return this.supplier.cobranded_disclaimer_date === ""
                    ? this.$t("supplier.NotConnected")
                    : this.$t("supplier.Connected");
            },
        },
        methods: {
            updateParent(response) {
                // Emit an add item element to refresh parent
                this.$emit("add-item", response.data);
            },
            showModal(target) {
                this.$bvModal.show(target);
            },
        },
    };
</script>
