export const adjustHelpLauncherPosition = (standardPostion) => {
    const styleId = "dynamic-helper-mobile-style"; // Unique ID for the style tag
    let styleElement = document.getElementById(styleId);

    if (standardPostion) {
        // If the style element doesn't exist, create and add it
        if (!styleElement) {
            styleElement = document.createElement("style");
            styleElement.id = styleId;
            styleElement.innerHTML = `
                @media (max-width: 1350px) {
                    iframe#launcher-frame {
                        left: -30px !important;
                    }
                }
            `;
            document.head.appendChild(styleElement);
        }
    } else {
        // Remove the style element if it exists
        if (styleElement) {
            styleElement.remove();
        }
    }
};
